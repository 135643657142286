import perform_json_response from 'javascripts/components/helpers/perform_json_response';
import Utils from 'javascripts/main/utils';
import CustomParametersValidator from 'javascripts/components/custom_parameters/validator';

$(document).on('ajax:success', '@self-contact-registration-form', (e, data) => {
  if (data != null && data.error == null) {
    perform_json_response(data);
  } else {
    $('@recaptcha-errors').html(data.error);
  }
});

$(document).on('ajax:before', '@self-contact-registration-form', (e) => {
  const form = $(e.target);
  const customParamsBlock = form.find('@new-contact-parameters-list-container');
  const customParametersValidator = new CustomParametersValidator(customParamsBlock);
  return customParametersValidator.validate();
});

$(document).on('click', '@self-contact-registration-form .btn-with-ok', (e) => {
  const form = $('@self-contact-registration-form');
  const datetimeContainers = form.find('.form-modal-custom-parameters-item.ftdatetime');
  datetimeContainers.map((_index, container) => {
    Utils.construct_hidden_datetime_param($(container), 'contact_draft');
  })
});

$(document).on('click', '@self-contact-registration-form .btn-with-ok', (e) => {
  const form = $('@self-contact-registration-form');
  const personal_data_checkbox = form.find('.accept-personal-data-block input#allow_processing');
  const holder = personal_data_checkbox.closest('.personal-data-input');

  if (!personal_data_checkbox.prop('checked')) {
    holder.addClass('field_with_errors');
    e.preventDefault();
  }
});

$(document).on('change', '.accept-personal-data-block #allow_processing', (e) => {
  const holder = $(e.target).closest('.personal-data-input');

  holder.removeClass('field_with_errors');
});
