`import 'jquery-ui/ui/keycode'`
`import initialize_submit_on_ctrl_enter from 'javascripts/components/shared/initialize_submit_on_ctrl_enter'`
`import globalSubmitOnCtrlEnterCallback from 'javascripts/components/shared/global_submit_on_ctrl_enter'`

ModalDialog = do ->
  list:  {}
  visiable_stack: []
  zindex_base: 50

  modal_object_build: (selector) ->
    $(selector).appendTo('.tlayout')

    modal:  $ selector
    selector: selector

    hide: ->
      ModalDialog._remove_from_visiable_stack(@selector)
      @modal.fadeOut(0, -> $(@).trigger('hide_modal'))
      #TODO: improve modal dialog system
      @modal.closest('.issue-preview').css('z-index': '9')

    show: (callback) ->
      ModalDialog._remove_from_visiable_stack(@selector)
      ModalDialog._add_to_visiable_stack(@selector)
      ModalDialog.add_zindex_for(@selector)
      ModalDialog.setPosition(@selector)
      @modal.fadeIn()
      # searches by "@signature-input" because it is hidden field controlled by the react component
      first_input = $(@selector).find('input:visible, @signature-input').not('.data-picker').last()
      form = first_input.closest('form')
      if form.length
        form.enableClientSideValidations()
        form.find('textarea, input[type="text"]').on 'keydown', (e) ->
          globalSubmitOnCtrlEnterCallback(e, form)
        form.find('.submit-btn').focus()
      ModalDialog._scroll_to_modal_top(@selector)

      if typeof CKEDITOR != 'undefined'
        instance = CKEDITOR.instances[form.find('textarea').attr('id')]
        if instance
          initialize_submit_on_ctrl_enter(instance, form)

      callback() if callback

  init_close_btn_for: (modal_obj) ->
    modal_obj.modal.find('@modal-dialog-close').click (e) ->
      modal_obj.hide()
      modal_obj.modal.find('form').find('textarea, input[type="text"]').off 'keydown'

  new: (selector) ->
    @list[selector].modal.remove() if @list[selector]
    @list[selector] = @modal_object_build(selector)
    modal_obj = @list[ selector ]
    modal_obj.modal.hide()

    @init_close_btn_for(modal_obj)

  init: ->
    @inited ||= do =>
      $(document).on 'click', '@modal-dialog-bg', =>
        item = @_last_visiable()
        if item && item.modal.data('closeByOutsideClick')
          item.hide()
          item.modal.find('form').find('textarea, input[type="text"]').off 'keydown'

      $(document).keyup (e) =>
        if e.keyCode is $.ui.keyCode.ESCAPE
          item = @_last_visiable()
          if item && !item.modal.data('dontCloseByEscape')
            item.hide()
            item.modal.find('form').find('textarea, input[type="text"]').off 'keydown'
      true

  add_zindex_for: (selector) ->
    for selector, index in @visiable_stack
      if modal = @list[ selector ]
        #TODO: improve modal dialog system
        newZIndex = if $(document).find('.preview-dialog:visible').length > 0
                      index + @zindex_base + 1
                    else
                      index + @zindex_base

        modal.modal.css { 'z-index': newZIndex }

  setPosition: (selector) ->
    modal = @list[selector]
    topPosition = $(document).scrollTop()

    modal.modal.css('top': topPosition)

  # HELPERS
  _last_index: (arr) -> arr.length - 1
  _last:       (arr) -> arr[ @_last_index(arr) ]
  _compact:    (arr) -> arr.filter (e) -> e

  _find_visiable_index: (sel) -> @visiable_stack.indexOf(sel)
  _compact_visiable_stack:    -> @visiable_stack = @_compact @visiable_stack
  _last_visiable:             -> @list[ @visiable_stack[ @_last_index(@visiable_stack) ] ]

  _add_to_visiable_stack: (selector) ->
    if @_find_visiable_index(selector) < 0
      @visiable_stack.push selector

  _remove_from_visiable_stack: (selector) ->
    index = @_find_visiable_index(selector)
    delete @visiable_stack[ index ]
    do @_compact_visiable_stack

  _scroll_to_modal_top: (selector) ->
    y = $(selector).offset().top
    # because of safari bug on scroll to 0
    if y == 0
      y = 1
    $(document).scrollTop(y)

`export default ModalDialog`
