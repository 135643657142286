`import updateCkeditorElementsOnForm from 'javascripts/components/shared/updateCkeditorElementsOnForm'`

initialize_submit_on_ctrl_enter = (instance, form) ->
  instance.on "key", (e) ->
    if e.data.keyCode == CKEDITOR.CTRL + 13
      if instance.getCommand("maximize").state == 1
        instance.execCommand('maximize')
      updateCkeditorElementsOnForm(form)
      form.submit()
      e.cancel()

`export default initialize_submit_on_ctrl_enter`
