import { fetch as fetchPolyfill } from 'whatwg-fetch';
import Turbolinks from 'javascripts/turbolinks';
import queryString from 'qs';

export default class RequestService {
  #lastResponseStatus = null;

  static requestHeaders({ noCache = false }) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken && csrfToken.getAttribute('content'),
      // Response format used for differ react requests and web json requests.
      // Requests difference in response format.
      // Web json expects html partials, react expects serialized object.
      ResponseFormat: 'serialized_object',
      'X-Requested-With': 'XMLHttpRequest',
    };
    if (noCache) { headers['Cache-Control'] = 'no-cache'; }

    return headers;
  }

  static responseRedirect(response) {
    return response.redirect && Object.prototype.hasOwnProperty.call(response.redirect, 'redirect_path')
           && response.redirect.redirect_path;
  }

  static fetchData(url, requestParams) {
    return fetchPolyfill(url, requestParams).catch((e) => { if (e.name !== 'AbortError') { throw e; } });
  }

  async handleResponce(request) {
    const response = await request;
    if (!response) { return false; }
    const responseBody = await response.json();
    this.#lastResponseStatus = response.status;
    const redirectPath = this.constructor.responseRedirect(responseBody);
    const redirectLocation = responseBody.redirect_location;

    if (redirectPath) {
      Turbolinks.visit(redirectPath);
      return false;
    }

    if (redirectLocation) {
      window.location.href = redirectLocation;
      return false;
    }

    return responseBody;
  }

  async post(url, params = {}, settings = {}) {
    const { signal } = settings;

    const requestBody = JSON.stringify(params);
    const requestParams = {
      headers: this.constructor.requestHeaders({}),
      body: requestBody,
      method: 'POST',
      signal,
    };

    return this.handleResponce(this.constructor.fetchData(url, requestParams));
  }

  async patch(url, params = {}, settings = {}) {
    const { signal } = settings;

    const requestBody = JSON.stringify(params);
    const requestParams = {
      headers: this.constructor.requestHeaders({}),
      body: requestBody,
      method: 'PATCH',
      signal,
    };

    return this.handleResponce(this.constructor.fetchData(url, requestParams));
  }

  async delete(url, params = {}, settings = {}) {
    const { signal } = settings;

    const requestBody = JSON.stringify(params);
    const requestParams = {
      headers: this.constructor.requestHeaders({}),
      body: requestBody,
      method: 'DELETE',
      signal,
    };

    return this.handleResponce(this.constructor.fetchData(url, requestParams));
  }

  async get(url, params = null, settings = {}) {
    const { signal, arrayFormat = 'brackets', noCache = false } = settings;

    let urlWithParams = url;
    if (params) {
      const connector = url?.includes('?') ? '&' : '?';

      urlWithParams = `${url}${connector}${queryString.stringify(params, { arrayFormat })}`;
    }

    const requestParams = { headers: this.constructor.requestHeaders({ noCache }), method: 'GET', signal };

    return this.handleResponce(this.constructor.fetchData(urlWithParams, requestParams));
  }

  getLastResponseStatus = () => this.#lastResponseStatus;
}
