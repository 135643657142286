import CustomCheckbox from 'javascripts/vendors/custom_checkbox';
import {
  init_general_date_picker,
  init_simple_date_and_time_masks,
} from 'javascripts/main/datetime_initializers';
import CustomSelect from 'javascripts/vendors/custom_select';

$(document).on('ready page:load', () => {
  CustomCheckbox.init();
  CustomSelect.init('@custom-select');
  init_general_date_picker();
  init_simple_date_and_time_masks();
  $('@self-contact-registration-form').enableClientSideValidations()
});

$(document).on('click', '@notification-line-close', (e) => {
  $(e.target).closest('@notification-line').slideUp('fast');
});

$(document).on('click', '.user-checkbox', () => {
  $('.btn-with-ok').removeClass('hidden');
});

window.recaptcha_success = () => {
  $('.error-message-line@recaptcha-errors').html('');
};
